define("additive-content/components/ac-landing-pages/configurator/action-wrapper", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@additive-apps/configurator/utils/helpers", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _runloop, _helpers, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this._classNames}} {{did-insert this._onInsertElement}}>
    <div
      class="action-click-wrapper absolute w-100 h-100 cpointer"
      role="button"
      {{on "click" this.onClick}}
    ></div>
  
    {{#unless @isReadOnly}}
      <AcLandingPages::Configurator::VariationSwitch
        @contentBlock={{this.contentBlock}}
        @isVisible={{this._isElementHovered}}
        @position={{@position}}
        @onChange={{@onChangeVariation}}
      />
      {{#if (and this._isElementHovered @hasActions)}}
        <div
          class="ac-landing-pages-configurator-action-wrapper__action-button-wrapper flex absolute right-0 br1 bg-white pv05 shadow-2"
        >
          <UiIconButton @icon="duplicate" @isSecondary={{true}} @onClick={{@onDuplicate}} />
          <UiIconButton @icon="delete" @isDanger={{true}} @onClick={{@onDelete}} />
        </div>
      {{/if}}
    {{/unless}}
  
    {{#if this._showWarning}}
      <div class="ac-landing-pages-configurator-warning-icon">
        {{svg-jar "warning" class="yellow" width="36" height="36"}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "pMkNf5Ow",
    "block": "[[[11,0],[16,0,[30,0,[\"_classNames\"]]],[4,[38,0],[[30,0,[\"_onInsertElement\"]]],null],[12],[1,\"\\n  \"],[11,0],[24,0,\"action-click-wrapper absolute w-100 h-100 cpointer\"],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[51,[30,1]],[[[1,\"    \"],[8,[39,3],null,[[\"@contentBlock\",\"@isVisible\",\"@position\",\"@onChange\"],[[30,0,[\"contentBlock\"]],[30,0,[\"_isElementHovered\"]],[30,2],[30,3]]],null],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"_isElementHovered\"]],[30,4]],null],[[[1,\"      \"],[10,0],[14,0,\"ac-landing-pages-configurator-action-wrapper__action-button-wrapper flex absolute right-0 br1 bg-white pv05 shadow-2\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@icon\",\"@isSecondary\",\"@onClick\"],[\"duplicate\",true,[30,5]]],null],[1,\"\\n        \"],[8,[39,6],null,[[\"@icon\",\"@isDanger\",\"@onClick\"],[\"delete\",true,[30,6]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[41,[30,0,[\"_showWarning\"]],[[[1,\"    \"],[10,0],[14,0,\"ac-landing-pages-configurator-warning-icon\"],[12],[1,\"\\n      \"],[1,[28,[35,7],[\"warning\"],[[\"class\",\"width\",\"height\"],[\"yellow\",\"36\",\"36\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@isReadOnly\",\"@position\",\"@onChangeVariation\",\"@hasActions\",\"@onDuplicate\",\"@onDelete\"],false,[\"did-insert\",\"on\",\"unless\",\"ac-landing-pages/configurator/variation-switch\",\"if\",\"and\",\"ui-icon-button\",\"svg-jar\"]]",
    "moduleName": "additive-content/components/ac-landing-pages/configurator/action-wrapper.hbs",
    "isStrictMode": false
  });
  /**
   * Loads and renders a content block
   *
   * @module ac-landing-pages
   * @class AcLandingPagesConfiguratorActionWrapper
   */
  let AcLandingPagesConfiguratorActionWrapper = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec4 = (0, _emberArgTypes.arg)(_propTypes.number), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AcLandingPagesConfiguratorActionWrapper extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the data of the content block
       *
       * @argument contentBlock
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "contentBlock", _descriptor, this);
      /**
       * whether the block has actions
       *
       * @argument hasActions
       * @property {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "hasActions", _descriptor2, this);
      /**
       * whether this block is active
       *
       * @argument isActive
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isActive", _descriptor3, this);
      /**
       * the position of this block
       *
       * @argument position
       * @type {Number}
       * @default null
       */
      _initializerDefineProperty(this, "position", _descriptor4, this);
      /**
       * the main HTML element of the component
       *
       * @property _element
       * @type {HTMLElement}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_element", _descriptor5, this);
      /**
       * whether the action wrapper is hovered
       *
       * @property _isElementHovered
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isElementHovered", _descriptor6, this);
      /**
       * callback which is executed when the action wrapper is clicked
       *
       * @argument onClick
       * @type {Function}
       */
      _initializerDefineProperty(this, "onClick", _descriptor7, this);
      /**
       * callback which is executed on delete
       *
       * @argument onDelete
       * @type {Function}
       */
      _initializerDefineProperty(this, "onDelete", _descriptor8, this);
      /**
       * callback which is executed on duplicate
       *
       * @argument onDuplicate
       * @type {Function}
       */
      _initializerDefineProperty(this, "onDuplicate", _descriptor9, this);
    }
    /**
     * computed class names
     *
     * @computed _classNames
     * @type {String}
     * @private
     */
    get _classNames() {
      let classNames = 'ac-landing-pages-configurator-action-wrapper absolute top-0 mw-100 h-100';
      if (this._isElementHovered || this.isActive) {
        classNames = `${classNames} ac-landing-pages-configurator-action-wrapper__is-selected`;
      }
      if (this._isElementHovered) {
        classNames = `${classNames} ac-landing-pages-configurator-action-wrapper__is-hovered`;
      }
      return classNames;
    }

    /**
     * whether a warning icon should be shown
     *
     * @computed _showWarning
     * @type {Boolean}
     * @private
     */
    get _showWarning() {
      const {
        contentBlock
      } = this;
      if (!(contentBlock !== null && contentBlock !== void 0 && contentBlock.model)) {
        return false;
      }
      let hasError = false;

      // iterate through all properties of this block and check if they are valid
      contentBlock.model.content.forEach(contentGroup => {
        var _contentGroup$propert;
        (_contentGroup$propert = contentGroup.properties) === null || _contentGroup$propert === void 0 || _contentGroup$propert.forEach(property => {
          if (['banner_link', 'link', 'usp_categories', 'usp_category', 'image_galleries', 'accommodations', 'image_gallery', 'image', 'video'].includes(property.id)) {
            let isHidden = false;
            contentBlock.model.content.forEach(contentGroup => {
              if (!contentGroup.properties) {
                return;
              }
              contentGroup.properties.forEach(contentProperty => {
                // hide external link property if action property is not 'link'
                if (contentProperty.id === 'action' && contentProperty.value !== 'link' && property.id === 'link') {
                  isHidden = true;
                } else if (contentProperty.id === 'banner_action' && contentProperty.value !== 'link' && property.id === 'banner_link') {
                  isHidden = true;
                } else if (contentProperty.id === 'show_image_galleries' && contentProperty.sync && !contentProperty.syncedValue || !contentProperty.sync && !contentProperty.value && property.id === 'image_galleries') {
                  isHidden = true;

                  // consider only usp_category or usp_categories
                } else if (contentProperty.id === 'show_categories' && ['usp_categories', 'usp_category'].includes(property.id)) {
                  isHidden = property.id === 'usp_categories' ? !contentProperty.value : contentProperty.value;
                } else if (contentProperty.id === 'media_type') {
                  isHidden = property.id === 'image_gallery' && contentProperty.value !== 'image_gallery' || property.id === 'image' && contentProperty.value !== 'image' || property.id === 'video' && contentProperty.value !== 'video';
                }
              });
              contentBlock.model.style.forEach(styleGroup => {
                if (!styleGroup.properties) {
                  return;
                }
                styleGroup.properties.forEach(styleProperty => {
                  // hide external link property if action property is not 'link'
                  if (styleProperty.id === 'show_accommodations' && !styleProperty.value && property.id === 'accommodations') {
                    isHidden = true;
                  }
                });
              });
            });
            if (!isHidden) {
              if ((0, _helpers.validateContentBlockValue)(property)) {
                hasError = true;
              }
            }
          } else {
            if ((0, _helpers.validateContentBlockValue)(property)) {
              hasError = true;
            }
          }
        });
      });
      contentBlock.model.style.forEach(styleGroup => {
        var _styleGroup$propertie;
        (_styleGroup$propertie = styleGroup.properties) === null || _styleGroup$propertie === void 0 || _styleGroup$propertie.forEach(property => {
          if ((0, _helpers.validateContentBlockValue)(property)) {
            hasError = true;
          }
        });
      });
      return hasError;
    }
    _onMouseEnterActionsWrapper() {
      this._isElementHovered = true;
    }
    _onMouseLeaveActionsWrapper() {
      this._isElementHovered = false;
    }
    _onInsertElement(element) {
      if (element) {
        this._element = element;
        this._onMouseEnterActionsWrapper = (0, _runloop.bind)(this, this._onMouseEnterActionsWrapper);
        this._onMouseLeaveActionsWrapper = (0, _runloop.bind)(this, this._onMouseLeaveActionsWrapper);
        this._element.addEventListener('mouseenter', this._onMouseEnterActionsWrapper, false);
        this._element.addEventListener('mouseleave', this._onMouseLeaveActionsWrapper, false);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "contentBlock", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasActions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "position", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_isElementHovered", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onDelete", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onDuplicate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onInsertElement"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcLandingPagesConfiguratorActionWrapper);
});